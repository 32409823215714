import React, { useState, useEffect } from "react";
import {
  FaTurkishLiraSign,
  FaClock,
  FaCheck,
  FaUser,
  FaArrowUp,
  FaArrowDown,
  FaRegCopy,
  FaMoneyBill,
} from "react-icons/fa6";
import { GrMoney } from "react-icons/gr";
import { useAuth } from "../../../config/AuthContext";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import "./Home.scss";
import { getDashboard, getTransactions } from "../../../config/Config";
import { TransactionTypeNames } from "../../../enums/TransactionType";
import { TransactionStatusNames } from "../../../enums/TransactionStatus";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { currencySymbols } from "../../../enums/MoneySymbols";

import { useLocation } from "react-router-dom";

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip);

const Home = ({
  accountAddress,
  isBalanceVisible,
  toggleBalanceVisibility,
}) => {
  const [currentBalance, setCurrentBalance] = useState("");
  const [pendingBalance, setPendingBalance] = useState("");
  const [remainingLimit, setRemainingLimit] = useState("");
  const [showFullAddress, setShowFullAddress] = useState(false);
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [currency, setCurrency] = useState(""); // Add state for currency

  const { user, isLoggedIn } = useAuth();
  const email = user?.username || {};

  const toggleFullAddress = () => {
    setShowFullAddress(!showFullAddress);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Copied to clipboard:", text);
        alert("Copied to clipboard");
      },
      (err) => {
        console.error("Could not copy text:", err);
      }
    );
  };

  const months = [
    { name: "Jan", amount: 220 },
    { name: "Feb", amount: 5000 },
    { name: "Mar", amount: 100 },
    { name: "Apr", amount: 150 },
    { name: "May", amount: 300 },
    { name: "Jun", amount: 450 },
    { name: "Jul", amount: 600 },
    { name: "Aug", amount: 250 },
    { name: "Sep", amount: 350 },
    { name: "Oct", amount: 500 },
    { name: "Nov", amount: 700 },
    { name: "Dec", amount: 400 },
  ];

  const data = {
    labels: months.map((month) => month.name),
    datasets: [
      {
        label: "Usage (TRY)",
        data: months.map((month) => month.amount),
        backgroundColor: [
          "#FF6384",
          "#5d9599",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
        borderRadius: 5, // Rounded corners for bars
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      title: {
        display: true,
        text: "Monthly Usage Summary",
        align: "start", // Metni sağa yaslı yapar
        font: {
          size: 16, // Font boyutu
          weight: 'bold', // Font ağırlığı
        },
        color: '#575757',
        padding: {
          bottom: 20, // Başlık altında boşluk bırakır
        },
      },
      
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide grid lines
        },
        ticks: {
          color: "#999", // Color for x-axis labels
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      const result = await getDashboard();
      if (
        result &&
        result.success &&
        Array.isArray(result.data) &&
        result.data.length > 0
      ) {
        const { balanceCurrent, balancePending, currency } = result.data[0];
        setCurrency(currency);
        setCurrentBalance(
          `${balanceCurrent.toLocaleString("tr-TR")} ${
            currencySymbols[currency] || currency
          }`
        );
        setPendingBalance(
          `${balancePending.toLocaleString("tr-TR")} ${
            currencySymbols[currency] || currency
          }`
        );
        setRemainingLimit(`∞`);
      } else {
        console.error("Error fetching dashboard data:", result.error);
      }
    };

    const fetchRecentTransactions = async () => {
      const result = await getTransactions();
      if (result.success) {
        const recent = result.data
          .filter(
            (transaction) =>
              transaction.transactionType === 1 ||
              transaction.transactionType === 2
          )
          .sort(
            (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
          )
          .slice(0, 5);
        setRecentTransactions(recent);
      } else {
        console.error("Error fetching transactions:", result.error);
      }
    };

    fetchDashboardData();
    fetchRecentTransactions();

    console.log("User info:", user);
    console.log("Is logged in:", isLoggedIn);
  }, [user, isLoggedIn]);
  

  const cards = [
    {
      key: 1,
      title: "Current Balance",
      amount: isBalanceVisible ? currentBalance : "********",
      icon: <GrMoney color="#ddd" size={30} />,
      color: "#10316B",
      amountStyle: { fontSize: "1.9em" },
    },
    {
      key: 4,
      title: "PLC Number",
      amountStyle: { fontSize: "1em" },
      amount: (
        <div
          style={{ display: "flex", alignItems: "center", wordBreak:'break-word'}}
          onClick={toggleFullAddress} // Handle click to toggle full address
        >
          {accountAddress &&
            (showFullAddress
              ? accountAddress
              : `${accountAddress.substring(0, 15)}...`)}
          <FaRegCopy
            color="#ddd"
            style={{ marginLeft: 10, cursor: "pointer" }}
            onClick={() => copyToClipboard(accountAddress)}
          />
        </div>
      ),
      icon: <FaUser color="ddd" size={30} />,
      color: "#1c4870",
      toggleFullAddress,
      titleColor: "#ddd", // Blue color for title
      amountColor: "#ddd",
    },
    {
      key: 2,
      title: "Pending Balance",
      amount: isBalanceVisible ? pendingBalance : "********",
      icon: <FaClock color="#ddd" size={30} />,
      color: "#0344a4",
      amountStyle: { fontSize: "1.5em" },
    },
    {
      key: 3,
      title: "Remaining Upload Limit",
      amount: isBalanceVisible ? remainingLimit : "********",
      icon: <FaCheck color="#ddd" size={30} />,
      color: "#452fa5",
    },
  ];

  const formatDate = (dateString) =>
    new Date(dateString).toLocaleString("tr-TR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

  const renderStatusIcon = (status) => {
    switch (status) {
      case 2:
        return <FaSpinner style={{ color: "orange" }} />;
      case 3:
        return <FaCheck style={{ color: "green" }} />;
      case 4:
        return <FaTimes style={{ color: "red" }} />;
      default:
        return getTransactionStatusName(status);
    }
  };

  const getTransactionTypeName = (value) =>
    TransactionTypeNames[value] || value;

  const getTransactionStatusName = (value) =>
    TransactionStatusNames[value] || value;

  return (
    <div className="home-container">
      <div style={{ paddingLeft: 20 }}>
        Welcome,{" "}
        <span style={{ fontWeight: 700, color: "#57606c" }}>{email}</span> !
      </div>
      
      <div className="card-container">
        {cards.map((item) => (
          <div
            key={item.key}
            className="card"
            style={{
              backgroundColor: item.color,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between", // Space between title and amount
              padding: "20px",
            }}
            onClick={item.toggleFullAddress ? item.toggleFullAddress : null}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center", // Vertically centers the items
                justifyContent: "flex-start", // Aligns content to the left
              }}
            >
              <span style={{ marginRight: 8 }}>{item.icon}</span>
              <span
                style={{
                  color: item.titleColor || "#ddd",
                  textAlign: "left", // Aligns text to the left
                }}
              >
                {item.title}
              </span>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start", // Aligns amount to the left
                marginTop: "20px", // Adjust spacing as needed
              }}
            >
              <span
                style={{
                  fontWeight: 700,
                  color: item.amountColor || "#ddd",
                  ...(item.amountStyle || {}),
                }}
              >
                {item.amount}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="user-info">
          <div className="wallet-usage-bar-chart">
            <Bar data={data} options={options} />
          </div>
        </div>
        <div className="recent-activities">
          <span>Recent Activities</span>
          {recentTransactions.length > 0 ? (
            <>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "0.6fr 2fr 2fr",
                  fontWeight: "600",
                  color: "#878787",
                  paddingLeft: 10,
                  paddingRight: 10,
                  fontSize: 13,
                }}
              >
                <div style={{ textAlign: "left" }}></div>
                <div style={{ textAlign: "left" }}>Date</div>
                <div style={{ textAlign: "right" }}>Amount</div>
              </div>

              {/* Transaction List */}
              <ul className="userinfo-items">
                {recentTransactions.map((activity) => (
                  <li key={activity.id}>
                    <div style={{ padding: 10 }}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "0.6fr 2fr 2fr",
                          alignItems: "center",
                          fontSize: 13,
                        }}
                      >
                        <div style={{ textAlign: "left" }}>
                          <span style={{ color: "#999", fontWeight: 500 }}>
                            {renderStatusIcon(activity.transactionStatus)}
                          </span>
                        </div>
                        <div style={{ textAlign: "left" }}>
                          <span
                            style={{
                              color: "#999",
                              fontWeight: 500,
                            }}
                          >
                            {formatDate(activity.transactionDate)}
                          </span>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <span style={{ color: "#999", fontWeight: 500 }}>
                            {currencySymbols[activity.currency] ||
                              activity.currency}{" "}
                            {activity.amount} of &nbsp;
                          </span>
                          <span
                            style={{
                              color:
                                activity.transactionType === 2
                                  ? "red"
                                  : activity.transactionType === 1
                                  ? "green"
                                  : "#999",
                              fontWeight: 500,
                            }}
                          >
                            {getTransactionTypeName(activity.transactionType)}
                            &nbsp;
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <div style={{ padding: 10, color: "#999", textAlign: "center" }}>
              No transfers to show.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
