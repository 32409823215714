import React, { useState } from "react";
import "./Register.scss";
import { register } from "../../config/Config";
import { useNavigate } from "react-router-dom";

import leftimage from "../../assets/login.jpeg";

const Register = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value, // Düzgün form veri güncellemesi
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { firstName, lastName, email, password, confirmPassword } = formData;

    // Email adresini küçük harfe dönüştür
    const lowerCaseEmail = email.toLowerCase();

    if (password !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    try {
      const result = await register(
        firstName,
        lastName,
        lowerCaseEmail, // Küçük harfe dönüştürülmüş email
        password,
        confirmPassword
      );
      // Kayıt başarılı
      if (result.success) {
        console.log("Registration successful:", result);
        navigate("/");
      } else {
        console.error("Registration failed:", result.error);
        alert(`Registration failed: ${result.error.title}`);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      if (error.response && error.response.data && error.response.data.errors) {
        let errorMessage = error.response.data.title + "\n";
        const errors = error.response.data.errors;
        for (const field in errors) {
          errorMessage += `${field}: ${errors[field].join(", ")}\n`;
        }
        alert(errorMessage);
      } else {
        alert("An error occurred, please try again.");
      }
    }
  };

  return (
    <div className="register-container">
      <div className="background-circles">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>

      <div className="section">
        <h2>Paylayercore</h2>
        <span>Create your Paylayercore account.</span>
        <div className="form-container">
          <form onSubmit={handleSubmit} className="signup-form">
            <div className="row">
              <input
                type="text"
                name="firstName"
                placeholder="Name"
                value={formData.firstName}
                onChange={handleChange}
              />
              <input
                type="text"
                name="lastName"
                placeholder="Surname"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="row">
              <input
                type="email"
                name="email"
                placeholder="E-mail Address"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="row">
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
              />
              <input
                type="password"
                name="confirmPassword"
                placeholder="Password Again"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
            </div>
            <button className="button" type="submit">
              Sign Up
            </button>
          </form>
        </div>
        <div className="p">
          <p>
            Are you already a member? <a href="/">Sign In</a>
          </p>
          <p>How can we help you?</p>
        </div>
        <div className="footer">
          <div className="divider"></div>
          <div className="rezerved">
            © <a href="/#">Paylayercore </a> All Rights Reserved.
          </div>
          <div className="divider"></div>
        </div>
      </div>
    </div>
  );
};

export default Register;
