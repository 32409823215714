import React, { useEffect, useState } from "react";
import "./Sendmoney.scss";
import { createSendMoney, getPaymentMethods } from "../../../config/Config";
import Lottie from "react-lottie";
import checkmarkAnimation from "../../../animations/checkmark.json";
import cancelAnimation from "../../../animations/cancel.json";
import { BsSendCheckFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { useAuth } from "../../../config/AuthContext"; // Kullanıcının bilgilerini almak için

const cleanAmount = (amount) => {
  return parseFloat(amount.replace(/,/g, ""));
};

const Sendmoney = ({ updateBalance, accountAddress }) => {
  const { user } = useAuth(); // Giriş yapan kullanıcı bilgisi
  const userPlcAddress = user ? user.plcAddress : ""; // Kullanıcının PLC adresi
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState("");
  const [currency, setCurrency] = useState("USDT");
  const [explanation, setExplanation] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [animationType, setAnimationType] = useState(null);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      setLoading(true);
      const result = await getPaymentMethods();
      if (result.success) {
        const filteredMethods = result.data.filter(
          (method) => method.acceptDeposit
        );
        setPaymentMethods(filteredMethods);

        if (
          filteredMethods.length > 0 &&
          filteredMethods[0].supportedCurrencies.length > 0
        ) {
          setCurrency(filteredMethods[0].supportedCurrencies[0].currency);
        }
      } else {
        setErrorMessage(result.error);
      }
      setLoading(false);
    };

    fetchPaymentMethods();
  }, []);

  const resetForm = () => {
    setAmount("");
    setAddress("");
    setExplanation("");
    setSuccessMessage(null);
    setErrorMessage(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Kullanıcının kendi PLC adresine para göndermesini engelle
    if (address === userPlcAddress || address === accountAddress) {
      setErrorMessage("You cannot send money to your own PLC address.");
      resetForm();

      return;
    }

    setShowModal(true);
  };

  const handleConfirm = async () => {
    setShowModal(false);

    const selectedMethod = paymentMethods.find(
      (method) => method.paymentMethod === activeTab
    );
    if (!selectedMethod) {
      console.error("No selected method found");
      return;
    }

    const cleanedAmount = cleanAmount(amount);
    if (cleanedAmount <= 0) {
      setErrorMessage("Please enter a value greater than zero.");
      return;
    }

    const result = await createSendMoney(
      address,
      cleanedAmount,
      currency,
      explanation
    );
    console.log(result);
    if (result.success) {
      setSuccessMessage("Send Money successful!");
      setErrorMessage(null);
      setAnimationType("checkmark");
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
        resetForm();
        updateBalance(); // Bakiye güncellemesini çağır
      }, 3000);
    } else {
      console.error("Send money failed:", result.error);
      const errorMessage =
        typeof result.error === "object"
          ? Object.values(result.error).join(", ")
          : result.error;
      setErrorMessage(errorMessage); // Hata mesajını ayarla
      resetForm();
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setAnimationType("cancel");
    setShowAnimation(true);
    setTimeout(() => setShowAnimation(false), 3000);
    resetForm()
  };

  const selectedMethod = paymentMethods.find(
    (method) => method.paymentMethod === activeTab
  );
  if (!selectedMethod) {
    return null;
  }

  const defaultOptionsCheckmark = {
    loop: false,
    autoplay: true,
    animationData: checkmarkAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptionsCancel = {
    loop: false,
    autoplay: true,
    animationData: cancelAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="deposit-container">
      <div className="deposit-area">
        <div className="send-header">
          <span className="send-header">Send Money</span>
        </div>

        <div className="contentitems-sendmoney">
          <form onSubmit={handleSubmit}>
            <label>PLC Address</label>
            <div className="amount">
              <input
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                type="text"
                name="address"
                className="amount-input"
                placeholder="Enter address"
                required
              />
            </div>
            <label>Amount</label>
            <div className="amount">
              <input
                type="text"
                name="amount"
                className="amount-input"
                value={amount}
                onChange={handleAmountChange}
                inputMode="decimal"
                placeholder="0.00"
              />
              <select
                name="currency"
                className="currency-select"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                {selectedMethod.supportedCurrencies.map((currency, index) => (
                  <option key={index} value={currency.currency}>
                    {currency.currency}
                  </option>
                ))}
              </select>
            </div>
            <label>Explanation</label>
            <div className="amount">
              <input
                type="text"
                name="explanation"
                className="amount-input"
                value={explanation}
                onChange={(e) => setExplanation(e.target.value)}
                placeholder="Enter explanation"
              />
            </div>
            <div className="button-container">
              <button className="button" type="submit">
                Continue
              </button>
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
          </form>
        </div>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h4>Confirmation</h4>
            <p>Are you sure you want to proceed with this transaction?</p>
            <div className="modal-buttons">
              <button onClick={handleConfirm} className="button-send">
                <BsSendCheckFill size={19} />
              </button>
              <button onClick={handleCancel} className="button-cancel">
                <MdCancel size={19} />
              </button>
            </div>
          </div>
        </div>
      )}

      {showAnimation && (
        <div className="animation-overlay">
          {animationType === "checkmark" ? (
            <Lottie
              options={defaultOptionsCheckmark}
              height={200}
              width={200}
            />
          ) : (
            <Lottie options={defaultOptionsCancel} height={200} width={200} />
          )}
        </div>
      )}
    </div>
  );
};

export default Sendmoney;
