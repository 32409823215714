import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Sidebar.scss";
import userimg from "../../assets/user-2.png";
import { useAuth } from "../../config/AuthContext";
import {
  FaEye,
  FaEyeSlash,
  FaHome,
  FaWallet,
  FaSignOutAlt,
  FaArrowUp,
  FaArrowDown,
  FaHouseUser,
} from "react-icons/fa";
import { TbReportSearch } from "react-icons/tb";
import { getDashboard, getAccInfo, getAccWallet } from "../../config/Config";

export const sidebaritems = [
  {
    key: 1,
    name: "Home",
    icon: <FaHome color="black" size={20} />,
    path: "/dashboard",
  },
  {
    key: 2,
    name: "Account",
    icon: <FaHouseUser color="black" size={20} />,
    path: "/dashboard/account",
  },
  {
    key: 3,
    name: "Deposit",
    icon: <FaWallet color="black" size={20} />,
    path: "/dashboard/deposit",
  },
  {
    key: 4,
    name: "Send Money",
    icon: <FaArrowUp color="black" size={20} />,
    path: "/dashboard/send-money",
  },
  {
    key: 5,
    name: "Withdraw Money",
    icon: <FaArrowDown color="black" size={20} />,
    path: "/dashboard/withdraw-money",
  },
  {
    key: 6,
    name: "Operations",
    icon: <TbReportSearch color="black" size={20} />,
    path: "/dashboard/operations",
  },
];

const Sidebar = ({
  isBalanceVisible,
  toggleBalanceVisibility,
  setWalletAddress,
  walletAddress,
  currentBalance,
  updateBalance,
}) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [showFullAddress, setShowFullAddress] = useState(false);
  const [error, setError] = useState(null);
  const [activeItem, setActiveItem] = useState(null); // State to track active item
  const [accounts, setAccounts] = useState([]);
  const [walletInfo, setWalletInfo] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [walletInfoError, setWalletInfoError] = useState(false);
  const handleNavigation = (path, key) => {
    setActiveItem(key); // Set the active item
    if (path === "/") {
      logout();
    }
    navigate(path);
  };

  const fetchWalletInfo = async (id) => {
    setLoading(true);
    setWalletInfoError(false);
    setTransactions([]);
    const result = await getAccWallet();
    if (result.success && Array.isArray(result.data) && result.data.length > 0) {
      const wallet = result.data[0];
      setWalletInfo(wallet);
      setWalletAddress(wallet.accountAddress); // Set the wallet address here
      setSelectedAccountId(id);
    } else {
      console.error("Failed to fetch wallet info:", result.error);
      setWalletInfoError(true);
    }
    setLoading(false);
  };
  

  useEffect(() => {
    const fetchAccountInfo = async () => {
      const result = await getAccInfo();

      if (result.success) {
        setAccounts(result.data);

        if (result.data.length === 1) {
          fetchWalletInfo(result.data[0].id);
        }
      } else {
        setError(result.error);
      }
    };

    fetchAccountInfo();
  }, [setWalletAddress]);

  useEffect(() => {
    updateBalance();
  }, [updateBalance]);
  useEffect(() => {
    setActiveItem(1); // Sayfa yüklendiğinde varsayılan olarak Home'u aktif yap
  }, []);
  return (
    <div className="sidebar-container">
      <header>
        Paylayer<span>Core</span>
      </header>
      {/* <img
        src={userimg}
        style={{
          backgroundColor: "white",
          borderRadius: '100%',
          width: "auto",
          height: "auto",
        }}
        alt="User"
      /> */}
      {/* <div>
        <span className="welcometext">Welcome</span>
      </div>
      <div className="userarea">
        <span className="username">{user.username || "Kullanıcı Adı"}</span>
      </div>
      <div className="balance">
        {" "}
        <span>Balance :</span>
        <span>{isBalanceVisible ? currentBalance : "********"}</span>
        <span>
          {isBalanceVisible ? (
            <FaEye onClick={toggleBalanceVisibility} />
          ) : (
            <FaEyeSlash onClick={toggleBalanceVisibility} />
          )}
        </span>
      </div>
      <div className="walletnum" onClick={toggleFullAddress}>
        <span>Wallet Address: </span>
        <span>
          {walletAddress &&
            (showFullAddress
              ? walletAddress
              : `${walletAddress.substring(0, 15)}...`)}
        </span>
      </div> */}
      <div className="sidebar-menu">
        <div>
          <ul className="sidebar-items">
            {sidebaritems.map((item) => (
              <React.Fragment key={item.key}>
                <li
                  onClick={() => handleNavigation(item.path, item.key)}
                  className={activeItem === item.key ? "active" : ""}
                >
                  {item.icon} <span>{item.name}</span>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
        <div>
          <div className="divider"> </div>
          <ul className="sidebar-signout">
            <li onClick={() => handleNavigation("/")}>
              <FaSignOutAlt color="#bf0000" size={20} /> <span>Sign Out</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
