import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  CategoryScale,
} from "chart.js";

Chart.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  CategoryScale
);

const WeeklyTransactionChart = () => {
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const data = {
    labels: daysOfWeek,
    datasets: [
      {
        label: "Deposit",
        data: [15, 59, 30, 81, 56, 55, 40], // Example data
        borderColor: "#8F19A6", // Purple color for Income
        backgroundColor: "rgba(108, 93, 211, 0.2)",
        borderWidth: 2,
        pointRadius: 0, // Remove points
        pointHoverRadius: 0, // Remove hover points
        tension: 0.4, // Smooth lines
      },
      {
        label: "Withdrawals",
        data: [28, 48, 40, 19, 86, 27, 90], // Example data
        borderColor: "#B7C1E2", // Yellow color for Withdrawals
        borderWidth: 2,
        pointRadius: 0, // Remove points
        pointHoverRadius: 0, // Remove hover points
        backgroundColor: "rgba(255, 195, 0, 0.2)",
        tension: 0.4, // Smooth lines
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Disable default legend
      },
      tooltip: {
        enabled: false, // Disable the tooltip
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false, // Remove the bottom border line
        },
        ticks: {
          font: {
            weight: 900, // Increase font weight of x-axis labels
          },
          padding: 10, // Increase padding for x-axis labels
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false, // Hide y-axis labels
        },
        border: {
          display: false, // Remove the left border line
        },
        min: 0, // Adjust these values to control the range of y-axis
        max: 100,
      },
    },
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 30,
        backgroundColor:"var(--card-background)" // Kart Arka Plan Rengi

      }}
    >
      <div
        className="weekly-transaction-chart"
        style={{ height: "auto", width: "100%" }}
      >
        <Line data={data} options={options} />
      </div>
      <div
        className="custom-legend"
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1px",
          gap: 20,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              borderRadius: 30,
              width: "12px",
              height: "12px",
              backgroundColor: "#8F19A6",
              display: "inline-block",
              marginRight: "5px",
            }}
          ></span>
          <span style={{ fontSize: 10, fontWeight: 700 }}>Deposit</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              borderRadius: 30,
              width: "12px",
              height: "12px",
              backgroundColor: "#B7C1E2",
              display: "inline-block",
              marginRight: "5px",
            }}
          ></span>
          <span style={{ fontSize: 10, fontWeight: 700 }}>Withdrawals</span>
        </div>
      </div>
    </div>
  );
};

export default WeeklyTransactionChart;
